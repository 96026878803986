
import { defineComponent } from "vue";
import { data } from "@/shared/notfound";
import CliveLogoLink from "@/components/atoms/link/CliveLogoLink/CliveLogoLink.vue";
import AppRouterLink from "@/components/atoms/link/AppRouterLink/AppRouterLink.vue";
export default defineComponent({
  name: "NotFound",
  components: { CliveLogoLink, AppRouterLink },
  setup() {
    const { title, description } = data;
    return {
      title,
      description
    };
  }
});
